/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Form, Stack, Card, Banner, BodyText, Input} from '@servicetitan/design-system';
import {Link} from 'components';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    a: "a",
    pre: "pre",
    code: "code",
    h1: "h1",
    div: "div",
    h2: "h2",
    hr: "hr",
    h3: "h3",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  if (!Form) _missingMdxReference("Form", false);
  if (!Form.Input) _missingMdxReference("Form.Input", true);
  if (!Form.TextArea) _missingMdxReference("Form.TextArea", true);
  if (!Form.TimePicker) _missingMdxReference("Form.TimePicker", true);
  if (!Stack) _missingMdxReference("Stack", false);
  if (!Stack.Item) _missingMdxReference("Stack.Item", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Input"), " can have a placeholder, a default (initial) value, a label and a description. Label can be ", React.createElement(_components.a, {
    href: "#required-and-optional"
  }, "required or optional"), " as well."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.Group widths=\"equal\">\n\t\t<Form.Input\n\t\t\tlabel=\"First Name\"\n\t\t\tplaceholder=\"Leia\"\n\t\t\tdefaultValue=\"Leia\"\n\t\t\trequired\n\t\t/>\n\t\t<Form.Input label=\"Last Name\" placeholder=\"Organa\" required />\n\t</Form.Group>\n\t<Form.Group grouped>\n\t\t<Form.Input label=\"Address\" placeholder=\"1834 Tantive Ave\" />\n\t\t<Form.Input width=\"3\" label=\"Age\" />\n\t</Form.Group>\n</Form>\n")), "\n", React.createElement(_components.h1, {
    id: "states",
    style: {
      position: "relative"
    }
  }, "States", React.createElement(_components.a, {
    href: "#states",
    "aria-label": "states permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Input"), " can provide a visual representations of its status with ", React.createElement(_components.code, null, "disabled"), ", ", React.createElement(_components.code, null, "error"), ", ", React.createElement(_components.code, null, "readonly"), " and ", React.createElement(_components.code, null, "loading"), " states. You can use any combination of those states for the experience."), "\n", React.createElement(_components.h2, {
    id: "default",
    style: {
      position: "relative"
    }
  }, "Default", React.createElement(_components.a, {
    href: "#default",
    "aria-label": "default permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.Input label=\"Default Input\" defaultValue=\"Leia\" />\n\t<Form.Input label=\"Empty input\" />\n\t<Form.Input label=\"Placeholder Input\" placeholder=\"Leia\" />\n\t<Form.Input label=\"Required Input\" defaultValue=\"Leia\" required />\n\t<Form.Input label=\"Input\" description=\"Description goes here...\" />\n</Form>\n")), "\n", React.createElement(_components.h2, {
    id: "disabled",
    style: {
      position: "relative"
    }
  }, "Disabled", React.createElement(_components.a, {
    href: "#disabled",
    "aria-label": "disabled permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.Input label=\"Default Input\" defaultValue=\"Leia\" disabled />\n\t<Form.Input label=\"Empty Input\" disabled />\n\t<Form.Input label=\"Placeholder Input\" placeholder=\"Leia\" disabled />\n\t<Form.Input label=\"Required Input\" defaultValue=\"Leia\" required disabled />\n</Form>\n")), "\n", React.createElement(_components.h2, {
    id: "error",
    style: {
      position: "relative"
    }
  }, "Error", React.createElement(_components.a, {
    href: "#error",
    "aria-label": "error permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.Input label=\"Default Input\" defaultValue=\"Leia\" error />\n\t<Form.Input label=\"Empty Input\" error />\n\t<Form.Input label=\"Placeholder Input\" placeholder=\"Leia\" error />\n\t<Form.Input label=\"Required Input\" defaultValue=\"Leia\" required error />\n\t<Form.Input\n\t\tlabel=\"Required Input\"\n\t\trequired\n\t\terror=\"This field cannot be left blank.\"\n\t/>\n</Form>\n")), "\n", React.createElement(_components.h2, {
    id: "readonly",
    style: {
      position: "relative"
    }
  }, "Readonly", React.createElement(_components.a, {
    href: "#readonly",
    "aria-label": "readonly permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.Input label=\"Default Input\" defaultValue=\"Leia\" readOnly />\n\t<Form.Input label=\"Empty Input\" readOnly />\n\t<Form.Input label=\"Placeholder Input\" placeholder=\"Leia\" readOnly />\n\t<Form.Input label=\"Required Input\" defaultValue=\"Leia\" required readOnly />\n</Form>\n")), "\n", React.createElement(_components.h2, {
    id: "loading",
    style: {
      position: "relative"
    }
  }, "Loading", React.createElement(_components.a, {
    href: "#loading",
    "aria-label": "loading permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.Input label=\"Default Input\" defaultValue=\"Leia\" loading />\n\t<Form.Input label=\"Empty Input\" loading />\n\t<Form.Input label=\"Placeholder Input\" placeholder=\"Leia\" loading />\n</Form>\n")), "\n", React.createElement(_components.h1, {
    id: "label-counter-and-placeholder",
    style: {
      position: "relative"
    }
  }, "Label, Counter, and Placeholder", React.createElement(_components.a, {
    href: "#label-counter-and-placeholder",
    "aria-label": "label counter and placeholder permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Labels should be used to describe the information we're asking the user to give us. Placeholders should be used to show an example of the content we're asking the user for. If we use placeholders as labels, once the user starts typing into the field, they lose the description of what we're asking for."), "\n", React.createElement(Banner, {
    title: "Do This",
    status: "success"
  }), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.Input label=\"First Name\" placeholder=\"Leia\" />\n\t<Form.Input label=\"Phone Number\" placeholder=\"(800) 555-1234\" />\n</Form>\n")), "\n", React.createElement(Banner, {
    title: "Don't do this",
    status: "critical"
  }), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.Input label=\"\" placeholder=\"First Name\" />\n\t<Form.Input label=\"\" placeholder=\"Phone Number\" />\n</Form>\n")), "\n", React.createElement(_components.h2, {
    id: "label-help",
    style: {
      position: "relative"
    }
  }, "Label Help", React.createElement(_components.a, {
    href: "#label-help",
    "aria-label": "label help permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Labels can have a help icon with a ", React.createElement(_components.a, {
    href: "/components/tooltip"
  }, "tooltip"), " to provide additional context to a label."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.Input\n\t\tlabel=\"Input Label\"\n\t\tlabelProps={{\n\t\t\thelp: 'This is help text',\n\t\t}}\n\t/>\n</Form>\n")), "\n", React.createElement(_components.h2, {
    id: "required-and-optional",
    style: {
      position: "relative"
    }
  }, "Required and Optional", React.createElement(_components.a, {
    href: "#required-and-optional",
    "aria-label": "required and optional permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "A visual indicator can be applied on a Input's label."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.Input\n\t\tlabel=\"Required Label\"\n\t\tlabelProps={{\n\t\t\trequired: true,\n\t\t}}\n\t/>\n\t<Form.Input\n\t\tlabel=\"Optional Label\"\n\t\tlabelProps={{\n\t\t\toptional: true,\n\t\t}}\n\t/>\n</Form>\n")), "\n", React.createElement(_components.h2, {
    id: "counter",
    style: {
      position: "relative"
    }
  }, "Counter", React.createElement(_components.a, {
    href: "#counter",
    "aria-label": "counter permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Counter can be displayed when there is a ", React.createElement(_components.code, null, "maxLength"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.Input label=\"Counter Example\" maxLength={20} showCounter />\n</Form>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "action",
    style: {
      position: "relative"
    }
  }, "Action", React.createElement(_components.a, {
    href: "#action",
    "aria-label": "action permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Input action can be used to combine a text input with an action ", React.createElement(_components.a, {
    href: "/components/button"
  }, "button"), ", making them visually merged into a single block."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.Group widths=\"5\">\n\t\t<Form.Input\n\t\t\tlabel=\"Search\"\n\t\t\taction={<Button iconName=\"search\" />}\n\t\t\tsmall\n\t\t/>\n\t</Form.Group>\n\t<Form.Group widths=\"4\">\n\t\t<Form.Input label=\"Set Job Duration\" action=\"Set\" />\n\t</Form.Group>\n\t<Form.Group widths=\"3\">\n\t\t<Form.Input label=\"Add Technician\" action=\"Add\" large />\n\t</Form.Group>\n</Form>\n")), "\n", React.createElement(_components.h1, {
    id: "addons",
    style: {
      position: "relative"
    }
  }, "Addons", React.createElement(_components.a, {
    href: "#addons",
    "aria-label": "addons permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Input addons can be used to show an ", React.createElement(_components.a, {
    href: "/components/icon"
  }, "icon"), " or a ", React.createElement(_components.code, null, "text"), " that help users enter information in the Input. These serve as decorative additions, not additional actions."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.Group widths=\"5\">\n\t\t<Form.Input label=\"Cost\" shortLabel=\"$\" small />\n\t</Form.Group>\n\t<Form.Group widths=\"4\">\n\t\t<Form.Input\n\t\t\tlabel=\"Job Duration\"\n\t\t\tshortLabel=\"days\"\n\t\t\tshortLabelPosition=\"right\"\n\t\t/>\n\t</Form.Group>\n\t<Form.Group widths=\"3\">\n\t\t<Form.Input\n\t\t\tlabel=\"Technician Name\"\n\t\t\tshortLabel={<Icon name=\"person\" />}\n\t\t\tlarge\n\t\t/>\n\t</Form.Group>\n</Form>\n")), "\n", React.createElement(_components.h3, {
    id: "addon-guidance",
    style: {
      position: "relative"
    }
  }, "Addon Guidance", React.createElement(_components.a, {
    href: "#addon-guidance",
    "aria-label": "addon guidance permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Addons are helpful when they enhance the understanding of an input. This can be particularly useful with units of measurement, where fields may have specific formats, and with icons that are universally understood."), "\n", React.createElement(_components.p, null, "Addons should be avoided when they don't serve a use for users or when the addon's meaning is not well understood. A good default is to design without an addon, and only adding one to further enhance its usability."), "\n", React.createElement(Banner, {
    status: "warning",
    title: "Caution. Addons may not convey additional context, adding unnecessary visual weight"
  }), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.Group widths=\"2\">\n\t\t<Form.Input\n\t\t\tlabel=\"New Business Unit\"\n\t\t\tshortLabel={<Icon name=\"public\" />}\n\t\t/>\n\t</Form.Group>\n\n\t<Form.Group widths=\"5\">\n\t\t<Form.Input label=\"ZIP Code\" shortLabel={<Icon name=\"place\" />} />\n\t</Form.Group>\n\n\t<Form.Group widths=\"1\">\n\t\t<Form.Input label=\"Post Title\" shortLabel={<Icon name=\"tag\" />} />\n\t</Form.Group>\n</Form>\n")), "\n", React.createElement(_components.h1, {
    id: "sizes",
    style: {
      position: "relative"
    }
  }, "Sizes", React.createElement(_components.a, {
    href: "#sizes",
    "aria-label": "sizes permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Inputs can appear in three different sizes. The medium size is the default size, while small and large will allow the inputs to resize to take up more and less space in a layout. These match up with our ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/button/"
  }, "Button")), " sizes."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.Input label=\"Small Input\" small />\n\t<Form.Input label=\"Default Input\" />\n\t<Form.Input label=\"Large Input\" large />\n</Form>\n")), "\n", React.createElement(_components.h1, {
    id: "focus",
    style: {
      position: "relative"
    }
  }, "Focus", React.createElement(_components.a, {
    href: "#focus",
    "aria-label": "focus permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "An input can be focused via a ", React.createElement(_components.code, null, "ref")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "() => {\n\tconst ref = React.createRef();\n\tconst onClick = () => {\n\t\tref.current.focus();\n\t};\n\n\treturn (\n\t\t<Form>\n\t\t\t<Stack spacing=\"1\">\n\t\t\t\t<Button onClick={onClick}>Focus</Button>\n\t\t\t\t<Input ref={ref} style={{ flexGrow: 1 }} />\n\t\t\t</Stack>\n\t\t</Form>\n\t);\n};\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "best-practices",
    style: {
      position: "relative"
    }
  }, "Best Practices", React.createElement(_components.a, {
    href: "#best-practices",
    "aria-label": "best practices permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Inputs should:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Be clearly labeled so it’s obvious to the user what they should enter into the field"), "\n", React.createElement(_components.li, null, "Only ask for information that’s really needed"), "\n", React.createElement(_components.li, null, "Validate input as soon as merchant has finished interacting with a field (but not before)"), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "content-guidelines",
    style: {
      position: "relative"
    }
  }, "Content Guidelines", React.createElement(_components.a, {
    href: "#content-guidelines",
    "aria-label": "content guidelines permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "use-a-label-for-all-input-fields",
    style: {
      position: "relative"
    }
  }, "Use a label for all input fields", React.createElement(_components.a, {
    href: "#use-a-label-for-all-input-fields",
    "aria-label": "use a label for all input fields permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "A label is a short, meaningful description that clearly indicates what the user is expected to enter. Labels should be 1 to 3 words and written in title case. Labels should primarily be nouns. Avoid using labels as CTAs. A label is not inline help and shouldn’t be used to provide instruction."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2,
    alignItems: "flex-start"
  }, React.createElement(Form.Input, {
    label: "First Name",
    className: "m-b-1"
  }), React.createElement(Form.Input, {
    label: "Last Name"
  }))), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2,
    alignItems: "flex-start"
  }, React.createElement(Form.Input, {
    placeholder: "Leia",
    className: "m-b-1"
  }), React.createElement(Form.Input, {
    placeholder: "Organa"
  }))), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement("br"), "\n", React.createElement(_components.h2, {
    id: "follow-capitalization-rules",
    style: {
      position: "relative"
    }
  }, "Follow capitalization rules", React.createElement(_components.a, {
    href: "#follow-capitalization-rules",
    "aria-label": "follow capitalization rules permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Input labels should be written in title case."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2,
    alignItems: "flex-start"
  }, React.createElement(Form.Input, {
    label: "First Name"
  }))), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2,
    alignItems: "flex-start"
  }, React.createElement(Form.Input, {
    label: "First name",
    placeholder: "Leia"
  }))), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement("br"), "\n", React.createElement(_components.h2, {
    id: "avoid-using-placeholder-text",
    style: {
      position: "relative"
    }
  }, "Avoid using placeholder text", React.createElement(_components.a, {
    href: "#avoid-using-placeholder-text",
    "aria-label": "avoid using placeholder text permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Placeholder text has several accessibility issues:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Text has low contrast which makes the text hard to read"), "\n", React.createElement(_components.li, null, "Text disappears as soon as the user starts typing"), "\n", React.createElement(_components.li, null, "Can’t accommodate additional context due to limited space"), "\n", React.createElement(_components.li, null, "Unreliable for screen readers"), "\n"), "\n", React.createElement(_components.p, null, "Use inline help to provide hints, formatting, and requirements."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.nngroup.com/articles/form-design-placeholders/"
  }, "Placeholders in Form Fields Are Harmful"), " (", React.createElement(_components.a, {
    href: "https://www.nngroup.com/"
  }, "Nielsen Norman Group"), ")"), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2,
    alignItems: "flex-start"
  }, React.createElement(Form.Input, {
    label: "First Name"
  }))), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2,
    alignItems: "flex-start"
  }, React.createElement(Form.Input, {
    label: "First name",
    placeholder: "Leia"
  }))), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement("br"), "\n", React.createElement(_components.h2, {
    id: "show-hints-formatting-and-requirements",
    style: {
      position: "relative"
    }
  }, "Show hints, formatting, and requirements", React.createElement(_components.a, {
    href: "#show-hints-formatting-and-requirements",
    "aria-label": "show hints formatting and requirements permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Inline help should explain a feature or the outcome of the actions the user is about to take."), "\n", React.createElement(_components.p, null, "The description should adapt to the situation and context. The guidance could be focused on ", React.createElement(_components.strong, null, "what"), " is needed, or it could describe ", React.createElement(_components.strong, null, "how"), " to enter it."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "An overall description of the input field"), "\n", React.createElement(_components.li, null, "Hints for what kind of information needs to be input"), "\n", React.createElement(_components.li, null, "Specific formatting examples or requirements"), "\n"), "\n", React.createElement(_components.p, null, "Do not repeat the label in order to prompt someone to interact with it."), "\n", React.createElement(_components.p, null, "Don’t add inline help when unneeded or solely to make it match other inputs that have inline help text."), "\n", React.createElement(_components.p, null, "Use sentence case, and only include period if more than one sentence is used."), "\n", React.createElement(_components.p, null, "Follow the ", React.createElement(_components.a, {
    href: "https://v1.anvil.servicetitan.com/patterns/forms/#inline-help"
  }, "inline help"), " content guidelines."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2,
    alignItems: "flex-start"
  }, React.createElement(Form.TimePicker, {
    label: "End Date",
    placeholder: "",
    description: "Format: HH:MM AM"
  }))), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2,
    alignItems: "flex-start"
  }, React.createElement(Form.TimePicker, {
    label: "End Date",
    description: "Enter a time"
  }))), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement("br"), "\n", React.createElement(_components.h2, {
    id: "keep-overlay-help-short-simple-and-scannable",
    style: {
      position: "relative"
    }
  }, "Keep overlay help short, simple, and scannable", React.createElement(_components.a, {
    href: "#keep-overlay-help-short-simple-and-scannable",
    "aria-label": "keep overlay help short simple and scannable permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Overlay help should be short and easily scannable, no longer than a single phrase or short sentence."), "\n", React.createElement(_components.p, null, "Avoid repeating the UI text. Use sentence case, and only include period if more than one sentence is used."), "\n", React.createElement(_components.p, null, "For more complex or important information, consider another method of presentation, e.g., inline help or a link to the knowledge base."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2
  }, React.createElement(Form.TextArea, {
    label: "Regional settings",
    placeholder: "",
    labelProps: {
      help: 'Regional settings are numbers, dates, and currencies related to your region'
    }
  }))), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Stack, {
    direction: "column",
    spacing: 2
  }, React.createElement(Form.TextArea, {
    label: "Regional settings",
    placeholder: "",
    labelProps: {
      help: 'The numbers, dates, and currencies related to your region are important because they can affect the way that your organization will make decisions for that region. Choose a regional setting that makes the most sense for your area.'
    }
  }))), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "related-content",
    style: {
      position: "relative"
    }
  }, "Related Content", React.createElement(_components.a, {
    href: "#related-content",
    "aria-label": "related content permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "components",
    style: {
      position: "relative"
    }
  }, "Components", React.createElement(_components.a, {
    href: "#components",
    "aria-label": "components permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "To build a full form, use the ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/form/"
  }, "Form")), " component."), "\n", React.createElement(_components.li, null, "For multiline text input, use a ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/textarea/"
  }, "TextArea")), "."), "\n"), "\n", React.createElement(_components.h2, {
    id: "patterns",
    style: {
      position: "relative"
    }
  }, "Patterns", React.createElement(_components.a, {
    href: "#patterns",
    "aria-label": "patterns permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/patterns/forms/"
  }, "Form"), " design pattern for how related controls are ordered."), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "importing",
    style: {
      position: "relative"
    }
  }, "Importing", React.createElement(_components.a, {
    href: "#importing",
    "aria-label": "importing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Banner, {
    status: "warning"
  }, React.createElement(BodyText, null, "We recommend using the ", React.createElement(Link, {
    href: "/components/form/"
  }, "Form"), ' ', "shorthand component ", React.createElement(_components.code, null, `<Form.Input />`), ". It automatically provide the correct Form grouping structure and spacing. You can import the standalone component for custom Form layouts.")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "code: true\n---\nimport { Input } from '@servicetitan/design-system';\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
